import { z } from "zod";

export const UserAuthSchema = z.object({
  email: z
    .string({
      required_error: "Email is required"
    })
    .email({
      message: "Must be a valid email"
    }),
  password: z
    .string({
      required_error: "Password is required"
    })
    .min(8, {
      message: "Password must be at least 8 characters"
    })
});

export const PasswordResetSchema = z.object({
  email: z
    .string({
      required_error: "Email is required"
    })
    .email({
      message: "Must be a valid email"
    })
});

export const MagicLinkSchema = z.object({
  email: z
    .string({
      required_error: "Email is required"
    })
    .email({
      message: "Must be a valid email"
    })
});

export type UserAuthSchema = z.infer<typeof UserAuthSchema>;
export type PasswordResetSchema = z.infer<typeof PasswordResetSchema>;
export type MagicLinkSchema = z.infer<typeof MagicLinkSchema>;
